import { Utente } from "@/models/Utente";
import { reactive } from "vue";

export const store = reactive<Store>({
    pageTitle: '',
    activeMenu: '',
    childPortfolio: false,
    loggedUser: null
})

interface Store {
    pageTitle: string,
    activeMenu: string,
    childPortfolio: boolean,
    loggedUser: Utente | null
}