<template>
    <div>
        <el-footer class="custom-footer">
            <p @click="$router.push('/cookie-policy')" style="cursor: pointer" class="pt-3">Cookie policy</p>
            <hr class="container">
            <p>
                Premio Forlano, {{ new Date().getFullYear() }} | tutti i diritti riservati
            </p>
        </el-footer>
    </div>
</template>

<style scoped>
.custom-footer {
    background-color: #001719;
    color: #fbfbfb;
    height: 110px;
}
</style>