import axios from "axios";
import router from "./router";
import { showNotification } from "./utils/utils";
import { store } from "./utils/store";

const api = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": process.env.VUE_APP_ROOT,
    "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT"
  }
});
api.interceptors.response.use(
  response => { return response },
  error => {
    if (error.response?.data == "token_error") {
      setTimeout(() => {
        showNotification("error", "Sessione scaduta. Effettuare di nuovo l'accesso");
        sessionStorage.setItem("loggedUser", "");
        store.loggedUser = null;
        router.push('/');
      }, 1000);
    } else {
      throw error;
    }
  });

export default api;