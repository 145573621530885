import { Utente } from "@/models/Utente";
import http from "../http-common";

export const findUtenteById = async (id: number, verified: boolean) => {
  return await http.get("/utente/findOne/" + id + "/" + verified, { withCredentials: verified });
}

export const findUtenteByLogin = async (utente: Utente) => {
  return await http.post("/utente/findByLogin", utente, { withCredentials: true });
}

export const storeProfileImage = async (image: FormData) => {
  return await http.post("/utente/storeImage", image, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export const updateProfilo = async (utente: Utente) => {
  return await http.post("/utente/updateProfile", utente, { withCredentials: true });
}

export const getUtentiByRole = async (role: string) => {
  return await http.get("/utente/getAllByRole/" + role);
}

export const getUtentiByRoleAndEnte = async (role: string, idEnte: number | undefined) => {
  return await http.get("/utente/getAllByRoleEnte/" + role + "/" + idEnte);
}

export const findUtenteByUsername = async (username: string, id: number | undefined) => {
  return await http.get("/utente/findByUsername/" + username + "/" + id);
}

export const newUtente = async (utente: Utente) => {
  return await http.post("/utente/new", utente);
}

export const updateUtente = async (utente: Utente) => {
  return await http.post("/utente/update", utente);
}

export const getUtentiByEnte = async (id_ente: number) => {
  return await http.get("/utente/getAllByEnte/" + id_ente);
}

export const findUtenteDetail = async (id: number) => {
  return await http.get("/utente/findDetail/" + id);
}

export const modifyProfileStatus = async (id: number, status: boolean) => {
  return await http.get("/utente/modifyStatus/" + id + "/" + status);
}

export const verifyUtente = async (data) => {
  return await http.post("/utente/verify", data);
}

export const modifyProfileRole = async (id: number, role: string) => { 
  return await http.get("/utente/modifyRole/" + id + "/" + role); 
}